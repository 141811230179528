(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("systemsApi"));
	else if(typeof define === 'function' && define.amd)
		define("locationsApi", ["systemsApi"], factory);
	else if(typeof exports === 'object')
		exports["locationsApi"] = factory(require("systemsApi"));
	else
		root["locationsApi"] = factory(root["systemsApi"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__670__) => {
return 