export enum SearchRadiusPreference {
    Zero = 0,
    five = 5,
    ten = 10,
    fifteen = 15,
    twenty = 20,
    twentyFive = 25,
    fifty = 50,
    hundred = 100
}
